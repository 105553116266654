import { collect, empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.15/Seq.js";
import { spreadProps, listGutter } from "./Common.fs.js";
import { int32ToString, createObj, equals } from "./.fable/fable-library.3.1.15/Util.js";
import { DraggableProps, ReactBeautifulDnd_draggable, ReactBeautifulDnd_droppable } from "./Feliz.ReactBeautifulDnd.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.38.0/Interop.fs.js";
import { WorkItem } from "./WorkItem.fs.js";
import { rangeDouble } from "./.fable/fable-library.3.1.15/Range.js";
import { NewTaskForm } from "./NewTaskForm.fs.js";
import { ofArray } from "./.fable/fable-library.3.1.15/List.js";
import { toArray } from "./.fable/fable-library.3.1.15/Option.js";

function getListStyle(isDraggingOver, isHovered) {
    return toList(delay(() => append(isDraggingOver ? singleton(["backgroundColor", "aquamarine"]) : (isHovered ? singleton(["backgroundColor", "lightyellow"]) : empty()), delay(() => append(singleton(["padding", listGutter]), delay(() => append(singleton(["minHeight", 100 + "%"]), delay(() => append(singleton(["height", "fit-content"]), delay(() => append(singleton(["boxSizing", "border-box"]), delay(() => singleton(["cursor", "pointer"])))))))))))));
}

export function Cell(cellInputProps) {
    const setNewTaskFormCellId = cellInputProps.setNewTaskFormCellId;
    const setIsHovered = cellInputProps.setIsHovered;
    const isHovered = cellInputProps.isHovered;
    const items = cellInputProps.items;
    const droppableId = cellInputProps.droppableId;
    const cellId = cellInputProps.cellId;
    const isAddingTask = equals(cellInputProps.newTaskFormCellId, cellId);
    return ReactBeautifulDnd_droppable(droppableId, (provided, snapshot) => createElement("div", createObj(toList(delay(() => append(spreadProps(provided.droppableProps), delay(() => append(singleton(["className", "droppable-container"]), delay(() => append(singleton(["ref", (elt) => {
        void provided.innerRef(elt);
    }]), delay(() => append(singleton(["onMouseEnter", (_arg1) => {
        if (!snapshot.isDraggingOver) {
            setIsHovered(cellId);
        }
    }]), delay(() => append(singleton(["onMouseLeave", (_arg2) => {
        setIsHovered(void 0);
    }]), delay(() => append(singleton(["onClick", (e) => {
        if (isHovered) {
            setNewTaskFormCellId(cellId);
        }
    }]), delay(() => append(singleton(["style", createObj(getListStyle(snapshot.isDraggingOver, isHovered))]), delay(() => singleton(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(collect((i) => {
        const item = items[i];
        return singleton(ReactBeautifulDnd_draggable([new DraggableProps(1, i), new DraggableProps(3, "key", item.id), new DraggableProps(0, int32ToString(item.id))], (provided_1, snapshot_1, _arg3) => createElement(WorkItem, {
            cellId: cellId,
            provided: provided_1,
            snapshot: snapshot_1,
            item: item,
        })));
    }, rangeDouble(0, 1, items.length - 1)), delay(() => append(isAddingTask ? singleton(createElement(NewTaskForm, {
        cellId: cellId,
        cellItems: items,
        onCancel: (_arg4) => {
            setNewTaskFormCellId(void 0);
        },
    })) : singleton(createElement("div", {
        style: createObj(toList(delay(() => append(singleton(["textAlign", "center"]), delay(() => append(singleton(["paddingBottom", 24 + "px"]), delay(() => (((!isHovered) ? true : snapshot.isDraggingOver) ? singleton(["visibility", "hidden"]) : empty())))))))),
        children: "+ add task",
    })), delay(() => ofArray(toArray(provided.placeholder))))))))))]))))))))))))))))))));
}

