import { Union } from "./.fable/fable-library.3.1.15/Types.js";
import { unit_type, obj_type, int32_type, union_type, lambda_type, class_type, string_type } from "./.fable/fable-library.3.1.15/Reflection.js";
import * as react from "react";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { keyValueList } from "./.fable/fable-library.3.1.15/MapUtil.js";
import { singleton, empty, collect, append, delay, toList } from "./.fable/fable-library.3.1.15/Seq.js";
import { createObj } from "./.fable/fable-library.3.1.15/Util.js";

export class DroppableProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DroppableId", "Children"];
    }
}

export function DroppableProps$reflection() {
    return union_type("Feliz.ReactBeautifulDnd.DroppableProps", [], DroppableProps, () => [[["Item", string_type]], [["Item", lambda_type(class_type("Fable.Import.ReactBeautifulDnd.DroppableProvided"), lambda_type(class_type("Fable.Import.ReactBeautifulDnd.DroppableStateSnapshot"), class_type("Fable.React.ReactElement")))]]]);
}

export class DraggableProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DraggableId", "Index", "Children", "Custom"];
    }
}

export function DraggableProps$reflection() {
    return union_type("Feliz.ReactBeautifulDnd.DraggableProps", [], DraggableProps, () => [[["Item", string_type]], [["Item", int32_type]], [["Item", lambda_type(class_type("Fable.Import.ReactBeautifulDnd.DraggableProvided"), lambda_type(class_type("Fable.Import.ReactBeautifulDnd.DraggableStateSnapshot"), lambda_type(class_type("Fable.Import.ReactBeautifulDnd.DraggableRubric"), class_type("Fable.React.ReactElement"))))]], [["Item1", string_type], ["Item2", obj_type]]]);
}

export class DragDropContextProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnDragEnd"];
    }
}

export function DragDropContextProps$reflection() {
    return union_type("Feliz.ReactBeautifulDnd.DragDropContextProps", [], DragDropContextProps, () => [[["Item", lambda_type(class_type("Fable.Import.ReactBeautifulDnd.DropResult"), lambda_type(class_type("Fable.Import.ReactBeautifulDnd.ResponderProvided"), unit_type))]]]);
}

export class ReactBeautifulDnd {
    constructor() {
    }
}

export function ReactBeautifulDnd$reflection() {
    return class_type("Feliz.ReactBeautifulDnd.ReactBeautifulDnd", void 0, ReactBeautifulDnd);
}

export function ReactBeautifulDnd_dragDropContext(props, children) {
    return react.createElement(DragDropContext, keyValueList(props, 1), ...children);
}

export function ReactBeautifulDnd_droppable(droppableId, body) {
    return react.createElement(Droppable, {
        droppableId: droppableId,
        children: body,
    });
}

export function ReactBeautifulDnd_draggable(props, body) {
    return react.createElement(Draggable, Object.assign(keyValueList(toList(delay(() => append(collect((p) => {
        if (p.tag === 3) {
            return empty();
        }
        else {
            return singleton(p);
        }
    }, props), delay(() => singleton(new DraggableProps(2, body)))))), 1), createObj(toList(delay(() => collect((p_1) => {
        if (p_1.tag === 3) {
            return singleton([p_1.fields[0], p_1.fields[1]]);
        }
        else {
            return empty();
        }
    }, props))))));
}

