import { add, change, tryFind, ofArray } from "./.fable/fable-library.3.1.15/Map.js";
import { collect as collect_1, append, fold, map } from "./.fable/fable-library.3.1.15/Array.js";
import { AppState, RecurringTaskStatus, WorkItem, CellId } from "./Hooks.fs.js";
import { parse } from "./.fable/fable-library.3.1.15/Date.js";
import { Array_groupBy } from "./.fable/fable-library.3.1.15/Seq2.js";
import { arrayHash, equalArrays } from "./.fable/fable-library.3.1.15/Util.js";
import { collect, map as map_1, delay, toArray } from "./.fable/fable-library.3.1.15/Seq.js";
import { Map_merge } from "./Helpers.fs.js";
import { map as map_2, defaultArg } from "./.fable/fable-library.3.1.15/Option.js";
import { toArray as toArray_1, remove, add as add_1 } from "./.fable/fable-library.3.1.15/Set.js";

export function update(storeCollapsedSwimlanes, state, msg) {
    const matchValue = [state, msg];
    let pattern_matching_result, collapsedSwimlanes, response, cellId_2, state_2, workItems, dstCellId, dstWorkItems, srcCellId, srcWorkItems, state_3, appState, cellId_3, workItemIdToRemove, appState_1, cellId_4, appState_2, appState_3, cellId_5, workItem, appState_4, cellId_6, workItem_1, appState_5, isCollapsed, swlId_2;
    if (matchValue[1].tag === 1) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 1;
            cellId_2 = matchValue[1].fields[0];
            state_2 = matchValue[0];
            workItems = matchValue[1].fields[1];
        }
    }
    else if (matchValue[1].tag === 2) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 2;
            dstCellId = matchValue[1].fields[1][0];
            dstWorkItems = matchValue[1].fields[1][1];
            srcCellId = matchValue[1].fields[0][0];
            srcWorkItems = matchValue[1].fields[0][1];
            state_3 = matchValue[0];
        }
    }
    else if (matchValue[1].tag === 5) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 3;
            appState = matchValue[0];
            cellId_3 = matchValue[1].fields[0];
            workItemIdToRemove = matchValue[1].fields[1];
        }
    }
    else if (matchValue[1].tag === 7) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 4;
            appState_1 = matchValue[0];
            cellId_4 = matchValue[1].fields[0];
        }
    }
    else if (matchValue[1].tag === 8) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 5;
            appState_2 = matchValue[0];
        }
    }
    else if (matchValue[1].tag === 3) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 6;
            appState_3 = matchValue[0];
            cellId_5 = matchValue[1].fields[0];
            workItem = matchValue[1].fields[1];
        }
    }
    else if (matchValue[1].tag === 6) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 7;
            appState_4 = matchValue[0];
            cellId_6 = matchValue[1].fields[0];
            workItem_1 = matchValue[1].fields[1];
        }
    }
    else if (matchValue[1].tag === 4) {
        if (matchValue[0] == null) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 8;
            appState_5 = matchValue[0];
            isCollapsed = matchValue[1].fields[0];
            swlId_2 = matchValue[1].fields[1];
        }
    }
    else {
        pattern_matching_result = 0;
        collapsedSwimlanes = matchValue[1].fields[1];
        response = matchValue[1].fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            const loadedItems = ofArray(map((tupledArg) => {
                const _arg1 = tupledArg[0];
                return [new CellId(0, _arg1[0], _arg1[1]), map((wi_1) => (new WorkItem(wi_1.id, wi_1.title, wi_1.class_of_service, wi_1.sort, parse(wi_1.date_created))), tupledArg[1])];
            }, Array_groupBy((wi) => [wi.column.id, wi.swimlane.id], response.work_items, {
                Equals: (x, y) => equalArrays(x, y),
                GetHashCode: (x) => arrayHash(x),
            })));
            const columns = toArray(delay(() => map_1((col) => col.id, response.columns)));
            const swimlanes = toArray(delay(() => map_1((swimlane) => swimlane.id, response.swimlanes)));
            return new AppState(response, Map_merge(ofArray(map((cellId) => {
                const value_1 = [cellId, []];
                return defaultArg(map_2((cellItems) => [cellId, cellItems], tryFind(cellId, loadedItems)), value_1);
            }, toArray(delay(() => collect((colId_1) => map_1((swlId_1) => (new CellId(0, colId_1, swlId_1)), swimlanes), columns))))), fold((state_1, rtItem) => change(new CellId(1, rtItem.recurring_task.id, (rtItem.recurring_task_status === "next_due") ? (new RecurringTaskStatus(0)) : (new RecurringTaskStatus(1))), (items) => {
                if (items != null) {
                    return append(items, [new WorkItem(rtItem.id, rtItem.title, rtItem.class_of_service, rtItem.sort, parse(rtItem.date_created))]);
                }
                else {
                    return [new WorkItem(rtItem.id, rtItem.title, rtItem.class_of_service, rtItem.sort, parse(rtItem.date_created))];
                }
            }, state_1), ofArray(map((cellId_1) => [cellId_1, []], collect_1((rt) => [new CellId(1, rt.id, new RecurringTaskStatus(0)), new CellId(1, rt.id, new RecurringTaskStatus(1))], response.recurring_tasks))), response.recurring_tasks_items)), void 0, collapsedSwimlanes);
        }
        case 1: {
            return new AppState(state_2.KanbanContent, add(cellId_2, workItems, state_2.GridState), state_2.HoveredCellId, state_2.CollapsedSwimlanes);
        }
        case 2: {
            return new AppState(state_3.KanbanContent, add(dstCellId, dstWorkItems, add(srcCellId, srcWorkItems, state_3.GridState)), state_3.HoveredCellId, state_3.CollapsedSwimlanes);
        }
        case 3: {
            const matchValue_1 = tryFind(cellId_3, appState.GridState);
            if (matchValue_1 == null) {
                return state;
            }
            else {
                return new AppState(appState.KanbanContent, add(cellId_3, matchValue_1.filter((wi_2) => (wi_2.id !== workItemIdToRemove)), appState.GridState), appState.HoveredCellId, appState.CollapsedSwimlanes);
            }
        }
        case 4: {
            return new AppState(appState_1.KanbanContent, appState_1.GridState, cellId_4, appState_1.CollapsedSwimlanes);
        }
        case 5: {
            return new AppState(appState_2.KanbanContent, appState_2.GridState, void 0, appState_2.CollapsedSwimlanes);
        }
        case 6: {
            const matchValue_2 = tryFind(cellId_5, appState_3.GridState);
            if (matchValue_2 == null) {
                return state;
            }
            else {
                return new AppState(appState_3.KanbanContent, add(cellId_5, append(matchValue_2, [workItem]), appState_3.GridState), appState_3.HoveredCellId, appState_3.CollapsedSwimlanes);
            }
        }
        case 7: {
            const matchValue_3 = tryFind(cellId_6, appState_4.GridState);
            if (matchValue_3 == null) {
                return state;
            }
            else {
                return new AppState(appState_4.KanbanContent, add(cellId_6, map((wi_3) => {
                    if (wi_3.id === workItem_1.id) {
                        return workItem_1;
                    }
                    else {
                        return wi_3;
                    }
                }, matchValue_3), appState_4.GridState), appState_4.HoveredCellId, appState_4.CollapsedSwimlanes);
            }
        }
        case 8: {
            const newCollapsedSwimlanes = isCollapsed ? add_1(swlId_2, appState_5.CollapsedSwimlanes) : remove(swlId_2, appState_5.CollapsedSwimlanes);
            storeCollapsedSwimlanes(toArray_1(newCollapsedSwimlanes));
            return new AppState(appState_5.KanbanContent, appState_5.GridState, appState_5.HoveredCellId, newCollapsedSwimlanes);
        }
        case 9: {
            return void 0;
        }
    }
}

