import { Record, Union } from "../fable-library.3.1.15/Types.js";
import { int32_type, obj_type, record_type, option_type, bool_type, list_type, class_type, string_type, union_type } from "../fable-library.3.1.15/Reflection.js";

export class HttpMethod extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GET", "POST", "PUT", "PATCH", "DELETE", "HEAD", "OPTIONS"];
    }
}

export function HttpMethod$reflection() {
    return union_type("Fable.SimpleHttp.HttpMethod", [], HttpMethod, () => [[], [], [], [], [], [], []]);
}

export class Header extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Header"];
    }
}

export function Header$reflection() {
    return union_type("Fable.SimpleHttp.Header", [], Header, () => [[["Item1", string_type], ["Item2", string_type]]]);
}

export class BodyContent extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Empty", "Text", "Binary", "Form"];
    }
}

export function BodyContent$reflection() {
    return union_type("Fable.SimpleHttp.BodyContent", [], BodyContent, () => [[], [["Item", string_type]], [["Item", class_type("Browser.Types.Blob")]], [["Item", class_type("Browser.Types.FormData")]]]);
}

export class ResponseTypes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Text", "Blob", "ArrayBuffer"];
    }
}

export function ResponseTypes$reflection() {
    return union_type("Fable.SimpleHttp.ResponseTypes", [], ResponseTypes, () => [[], [], []]);
}

export class HttpRequest extends Record {
    constructor(url, method, headers, withCredentials, overridenMimeType, overridenResponseType, content) {
        super();
        this.url = url;
        this.method = method;
        this.headers = headers;
        this.withCredentials = withCredentials;
        this.overridenMimeType = overridenMimeType;
        this.overridenResponseType = overridenResponseType;
        this.content = content;
    }
}

export function HttpRequest$reflection() {
    return record_type("Fable.SimpleHttp.HttpRequest", [], HttpRequest, () => [["url", string_type], ["method", HttpMethod$reflection()], ["headers", list_type(Header$reflection())], ["withCredentials", bool_type], ["overridenMimeType", option_type(string_type)], ["overridenResponseType", option_type(ResponseTypes$reflection())], ["content", BodyContent$reflection()]]);
}

export class ResponseContent extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Text", "Blob", "ArrayBuffer", "Unknown"];
    }
}

export function ResponseContent$reflection() {
    return union_type("Fable.SimpleHttp.ResponseContent", [], ResponseContent, () => [[["Item", string_type]], [["Item", class_type("Browser.Types.Blob")]], [["Item", class_type("Fable.Core.JS.ArrayBuffer")]], [["Item", obj_type]]]);
}

export class HttpResponse extends Record {
    constructor(statusCode, responseText, responseType, responseHeaders, content) {
        super();
        this.statusCode = (statusCode | 0);
        this.responseText = responseText;
        this.responseType = responseType;
        this.responseHeaders = responseHeaders;
        this.content = content;
    }
}

export function HttpResponse$reflection() {
    return record_type("Fable.SimpleHttp.HttpResponse", [], HttpResponse, () => [["statusCode", int32_type], ["responseText", string_type], ["responseType", string_type], ["responseHeaders", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["content", ResponseContent$reflection()]]);
}

