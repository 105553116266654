import { Union } from "./.fable/fable-library.3.1.15/Types.js";
import { class_type, union_type, bool_type, lambda_type, unit_type, string_type } from "./.fable/fable-library.3.1.15/Reflection.js";
import * as react from "react";
import react$002Deasy$002Dedit from "react-easy-edit";
import { keyValueList } from "./.fable/fable-library.3.1.15/MapUtil.js";

export class EasyEditProps$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Type", "Value", "OnSave", "OnCancel", "OnFocus", "OnBlur", "SaveOnBlur"];
    }
}

export function EasyEditProps$1$reflection(gen0) {
    return union_type("Feliz.ReactEasyEdit.EasyEditProps`1", [gen0], EasyEditProps$1, () => [[["Item", string_type]], [["Item", gen0]], [["Item", lambda_type(gen0, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", bool_type]]]);
}

export class ReactEasyEdit {
    constructor() {
    }
}

export function ReactEasyEdit$reflection() {
    return class_type("Feliz.ReactEasyEdit.ReactEasyEdit", void 0, ReactEasyEdit);
}

export function ReactEasyEdit_easyEdit_791481ED(props) {
    return react.createElement(react$002Deasy$002Dedit, keyValueList(props, 1));
}

