import { interpolate, toText } from "./.fable/fable-library.3.1.15/String.js";
import { copyTo, fill } from "./.fable/fable-library.3.1.15/Array.js";
import { add, fold } from "./.fable/fable-library.3.1.15/Map.js";
import { ofArray } from "./.fable/fable-library.3.1.15/List.js";

export function Array_removeItem(index, array) {
    if ((index < 0) ? true : (index >= array.length)) {
        throw (new Error(toText(interpolate("Invalid index: %P() in array of length %P()", [index, array.length]))));
    }
    const res = fill(new Array(array.length - 1), 0, array.length - 1, null);
    for (let i = 0; i <= (index - 1); i++) {
        res[i] = array[i];
    }
    for (let i_1 = index + 1; i_1 <= (array.length - 1); i_1++) {
        res[i_1 - 1] = array[i_1];
    }
    return res;
}

export function Array_insertAt(index, value, array) {
    if ((index < 0) ? true : (index > array.length)) {
        throw (new Error(toText(interpolate("Invalid index: %P() in array of length %P()", [index, array.length]))));
    }
    const res = fill(new Array(array.length + 1), 0, array.length + 1, null);
    copyTo(array, 0, res, 0, index);
    res[index] = value;
    copyTo(array, index, res, index + 1, array.length - index);
    return res;
}

export function Map_merge(map1, map2) {
    return fold((baseMap, key, value) => add(key, value, baseMap), map2, map1);
}

export function CellStyle_gridPos(i, j) {
    return ofArray([["gridColumn", (i + " / ") + i], ["gridRow", (j + " / ") + j]]);
}

