import { map, delay, toList } from "./.fable/fable-library.3.1.15/Seq.js";

export const kanbanerApiEndpoint = process.env["KANBANER_API_ENDPOINT"] ? process.env["KANBANER_API_ENDPOINT"] : '';

export const kanbanerApiToken = process.env["KANBANER_API_TOKEN"] ? process.env["KANBANER_API_TOKEN"] : '';

export const listGutter = 8;

export function spreadProps(o) {
    return toList(delay(() => map((propName) => [propName, o[propName]], Object.keys(o))));
}

export function spreadStyleProps(o) {
    return toList(delay(() => map((propName) => [propName, o[propName]], Object.keys(o))));
}

