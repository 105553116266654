import { createElement } from "react";
import * as react from "react";
import { collect, empty, sumBy, map, singleton, append, delay, toList } from "./.fable/fable-library.3.1.15/Seq.js";
import { comparePrimitives, equals, createObj } from "./.fable/fable-library.3.1.15/Util.js";
import { CellStyle_gridPos } from "./Helpers.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.38.0/Interop.fs.js";
import { FSharpMap__get_Item } from "./.fable/fable-library.3.1.15/Map.js";
import { KanbanContentResponse$reflection, useKanbanContent, useLocalStorage, encodeCellId, RecurringTaskStatus, AppStateAction, appContext, CellId } from "./Hooks.fs.js";
import { map as map_1, tryPick } from "./.fable/fable-library.3.1.15/Array.js";
import { interpolate, toText } from "./.fable/fable-library.3.1.15/String.js";
import { rangeDouble } from "./.fable/fable-library.3.1.15/Range.js";
import { React_contextProvider_34D9BBBD, useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505, useReact_useContext_37FA55CF } from "./.fable/Feliz.1.38.0/React.fs.js";
import { value as value_28, some } from "./.fable/fable-library.3.1.15/Option.js";
import { ofSeq, intersect, ofArray, FSharpSet__Contains } from "./.fable/fable-library.3.1.15/Set.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreSharp from "@material-ui/icons/ExpandMoreSharp";
import ExpandLessSharp from "@material-ui/icons/ExpandLessSharp";
import { Cell } from "./Cell.fs.js";
import { DragDropContextProps, ReactBeautifulDnd_dragDropContext } from "./Feliz.ReactBeautifulDnd.fs.js";
import { handleDragEnd } from "./DragDropHandlers.fs.js";
import { singleton as singleton_1 } from "./.fable/fable-library.3.1.15/List.js";
import { update } from "./AppStateReducer.fs.js";
import { SimpleJson_tryParse } from "./.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { Convert_fromJson } from "./.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";
import { createTypeInfo } from "./.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";

export function ColumnTitles(columnTitlesInputProps) {
    const kanbanContent = columnTitlesInputProps.kanbanContent;
    const gridState = columnTitlesInputProps.gridState;
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        const columns = kanbanContent.columns;
        return append(singleton(createElement("div", {
            className: "column-title-container",
            style: createObj(toList(delay(() => append(CellStyle_gridPos(1, 1), delay(() => singleton(["borderLeft", (((0 + "px ") + "none") + " ") + "transparent"])))))),
        })), delay(() => map((i) => createElement("div", {
            className: "column-title-container",
            style: createObj(CellStyle_gridPos(i + 1, 1)),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const col = columns[i - 1];
                const patternInput = [col.name, col.id];
                const colId = patternInput[1] | 0;
                return append(singleton(createElement("h2", {
                    className: "column-title",
                    children: patternInput[0],
                })), delay(() => {
                    let value_11;
                    const itemCount = sumBy((swl) => FSharpMap__get_Item(gridState, new CellId(0, colId, swl.id)).length, kanbanContent.swimlanes, {
                        GetZero: () => 0,
                        Add: (x, y) => (x + y),
                    }) | 0;
                    const wipLimit = tryPick((col_1) => {
                        if (col_1.id === colId) {
                            return col_1.wip_limit;
                        }
                        else {
                            return void 0;
                        }
                    }, columns);
                    if (wipLimit == null) {
                        return empty();
                    }
                    else {
                        return singleton((value_11 = toText(interpolate("(%P()/%P())", [itemCount, wipLimit])), createElement("h4", {
                            children: [value_11],
                        })));
                    }
                }));
            })))),
        }), rangeDouble(1, 1, columns.length))));
    })));
}

export function SwimlaneTitles(swimlaneTitlesInputProps) {
    const kanbanContent = swimlaneTitlesInputProps.kanbanContent;
    const gridState = swimlaneTitlesInputProps.gridState;
    const patternInput = useReact_useContext_37FA55CF(appContext);
    const appState = patternInput[0];
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        const swimlanes = kanbanContent.swimlanes;
        return map((i) => createElement("div", {
            className: "row-title-container",
            style: createObj(toList(delay(() => append(singleton(["position", "relative"]), delay(() => CellStyle_gridPos(1, i + 1)))))),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let children;
                const swl = swimlanes[i - 1];
                const patternInput_1 = [swl.name, swl.id];
                const swlId = patternInput_1[1] | 0;
                const itemCount = sumBy((col) => {
                    if (equals(col.wip_limit, null)) {
                        return 0;
                    }
                    else {
                        return FSharpMap__get_Item(gridState, new CellId(0, col.id, swlId)).length | 0;
                    }
                }, kanbanContent.columns, {
                    GetZero: () => 0,
                    Add: (x, y) => (x + y),
                }) | 0;
                const wipLimit = tryPick((swl_1) => {
                    if (swl_1.id === swlId) {
                        return some(swl_1.wip_limit);
                    }
                    else {
                        return void 0;
                    }
                }, swimlanes);
                return append(singleton((children = toList(delay(() => append(singleton(createElement("h2", {
                    className: "row-title",
                    children: patternInput_1[0],
                })), delay(() => {
                    let value_9, value_8;
                    return (wipLimit == null) ? singleton((value_9 = toText(interpolate("(%P())", [itemCount])), createElement("h4", {
                        children: [value_9],
                    }))) : singleton((value_8 = toText(interpolate("(%P()/%P())", [itemCount, value_28(wipLimit)])), createElement("h4", {
                        children: [value_8],
                    })));
                })))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), delay(() => singleton(createElement("div", createObj(toList(delay(() => append(singleton(["style", {
                    position: "absolute",
                    top: 6,
                    left: 8,
                }]), delay(() => {
                    const isCollapsed = (appState == null) ? false : FSharpSet__Contains(appState.CollapsedSwimlanes, swlId);
                    return singleton(["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["onClick", (_arg1) => {
                        patternInput[1](new AppStateAction(4, !isCollapsed, swlId));
                    }], ["style", {
                        width: 20,
                        height: 20,
                    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (isCollapsed ? singleton(react.createElement(ExpandMoreSharp, {})) : singleton(react.createElement(ExpandLessSharp, {})))))))]])])]);
                })))))))));
            })))),
        }), rangeDouble(1, 1, swimlanes.length));
    })));
}

export function RecurringTasks(recurringTasksInputProps) {
    const setIsHovered = recurringTasksInputProps.setIsHovered;
    const setNewTaskFormCellId = recurringTasksInputProps.setNewTaskFormCellId;
    const newTaskFormCellId = recurringTasksInputProps.newTaskFormCellId;
    const appState = recurringTasksInputProps.appState;
    const kanbanContent = appState.KanbanContent;
    const hoveredCell = appState.HoveredCellId;
    const gridState = appState.GridState;
    const patternInput = [kanbanContent.recurring_tasks, kanbanContent.swimlanes];
    const swimlanes = patternInput[1];
    const recurring_tasks = patternInput[0];
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append(singleton(createElement("div", {
        className: "column-title-container second-row",
        style: createObj(toList(delay(() => append(CellStyle_gridPos(1, 2 + swimlanes.length), delay(() => singleton(["borderLeft", (((0 + "px ") + "none") + " ") + "transparent"])))))),
    })), delay(() => append(collect((matchValue) => singleton(createElement("div", {
        className: "column-title-container second-row",
        style: createObj(CellStyle_gridPos(matchValue[0], 2 + swimlanes.length)),
        children: Interop_reactApi.Children.toArray([createElement("h2", {
            className: "row-title",
            children: matchValue[1],
        })]),
    })), [[2, "Next Due"], [3, "Last Done"]]), delay(() => {
        const startRowOffset = (2 + swimlanes.length) | 0;
        return collect((i_1) => {
            const rt = recurring_tasks[i_1 - 1];
            return append(singleton(createElement("div", {
                className: "row-title-container",
                style: createObj(CellStyle_gridPos(1, i_1 + startRowOffset)),
                children: Interop_reactApi.Children.toArray([createElement("h2", {
                    className: "row-title",
                    children: rt.name,
                })]),
            })), delay(() => {
                const nextDueCellId = new CellId(1, rt.id, new RecurringTaskStatus(0));
                const lastDoneCellId = new CellId(1, rt.id, new RecurringTaskStatus(1));
                return append(singleton(createElement("div", {
                    className: "cell-container",
                    style: createObj(CellStyle_gridPos(2, i_1 + startRowOffset)),
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        const nextDueKey = encodeCellId(nextDueCellId);
                        const isHovered = equals(hoveredCell, nextDueCellId);
                        return singleton(createElement(Cell, {
                            cellId: nextDueCellId,
                            droppableId: nextDueKey,
                            items: FSharpMap__get_Item(gridState, nextDueCellId),
                            isHovered: isHovered,
                            setIsHovered: setIsHovered,
                            newTaskFormCellId: newTaskFormCellId,
                            setNewTaskFormCellId: setNewTaskFormCellId,
                        }));
                    })))),
                })), delay(() => singleton(createElement("div", {
                    className: "cell-container",
                    style: createObj(CellStyle_gridPos(3, i_1 + startRowOffset)),
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        const lastDoneKey = encodeCellId(lastDoneCellId);
                        const isHovered_1 = equals(hoveredCell, lastDoneCellId);
                        return singleton(createElement(Cell, {
                            cellId: lastDoneCellId,
                            droppableId: lastDoneKey,
                            items: FSharpMap__get_Item(gridState, lastDoneCellId),
                            isHovered: isHovered_1,
                            setIsHovered: setIsHovered,
                            newTaskFormCellId: newTaskFormCellId,
                            setNewTaskFormCellId: setNewTaskFormCellId,
                        }));
                    })))),
                }))));
            }));
        }, rangeDouble(1, 1, recurring_tasks.length));
    })))))));
}

export function App() {
    const patternInput = useReact_useContext_37FA55CF(appContext);
    const dispatch = patternInput[1];
    const appState = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setNewTaskFormCellId = patternInput_1[1];
    const newTaskFormCellId = patternInput_1[0];
    return ReactBeautifulDnd_dragDropContext(singleton_1(new DragDropContextProps(0, (dropResult, arg20$0040) => {
        handleDragEnd(appState, dispatch, dropResult, arg20$0040);
    })), [createElement("div", {
        style: {
            height: 110 + "vh",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (appState != null) {
                const kanbanContent = appState.KanbanContent;
                const items = appState.GridState;
                const hoveredCell = appState.HoveredCellId;
                const appState_1 = appState;
                const patternInput_2 = [kanbanContent.columns, kanbanContent.swimlanes];
                const swimlanes = patternInput_2[1];
                const columns = patternInput_2[0];
                return singleton(createElement("div", {
                    style: {
                        display: "grid",
                        gridTemplateColumns: ((("repeat(" + (columns.length + 1)) + ", ") + 175) + "px)",
                        gridTemplateRows: toText(interpolate("repeat(%P(), auto)", [swimlanes.length + 1])),
                        backgroundColor: "#F9F9FF",
                        width: "fit-content",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement(ColumnTitles, {
                        gridState: items,
                        kanbanContent: kanbanContent,
                    })), delay(() => append(singleton(createElement(SwimlaneTitles, {
                        gridState: items,
                        kanbanContent: kanbanContent,
                    })), delay(() => {
                        const setIsHovered = (cellIdOpt) => {
                            if (cellIdOpt != null) {
                                dispatch(new AppStateAction(7, cellIdOpt));
                            }
                            else {
                                dispatch(new AppStateAction(8));
                            }
                        };
                        return append(collect((swlIdx) => {
                            const isSwlCollapsed = FSharpSet__Contains(appState_1.CollapsedSwimlanes, swimlanes[swlIdx - 1].id);
                            return collect((colIdx) => {
                                const cellId_1 = new CellId(0, columns[colIdx - 1].id, swimlanes[swlIdx - 1].id);
                                const cellIdKey = encodeCellId(cellId_1);
                                return singleton(createElement("div", createObj(toList(delay(() => append(singleton(["className", "cell-container"]), delay(() => append(singleton(["style", createObj(toList(delay(() => append(CellStyle_gridPos(colIdx + 1, swlIdx + 1), delay(() => (isSwlCollapsed ? singleton(["backgroundColor", "#E0E0E0"]) : empty()))))))]), delay(() => ((!isSwlCollapsed) ? singleton(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                    const isHovered = equals(hoveredCell, cellId_1);
                                    return singleton(createElement(Cell, {
                                        cellId: cellId_1,
                                        droppableId: cellIdKey,
                                        items: FSharpMap__get_Item(items, cellId_1),
                                        isHovered: isHovered,
                                        setIsHovered: setIsHovered,
                                        newTaskFormCellId: newTaskFormCellId,
                                        setNewTaskFormCellId: setNewTaskFormCellId,
                                    }));
                                }))))]) : empty()))))))))));
                            }, rangeDouble(1, 1, columns.length));
                        }, rangeDouble(1, 1, swimlanes.length)), delay(() => singleton(createElement(RecurringTasks, {
                            appState: appState_1,
                            newTaskFormCellId: newTaskFormCellId,
                            setNewTaskFormCellId: setNewTaskFormCellId,
                            setIsHovered: setIsHovered,
                        }))));
                    })))))))),
                }));
            }
            else {
                return singleton(createElement("h1", {
                    style: {
                        marginTop: 40 + "vh",
                        textAlign: "center",
                    },
                    children: "loading...",
                }));
            }
        })))),
    })]);
}

export function Root() {
    const patternInput = useLocalStorage("collapsedSwimlanes", new Int32Array([]));
    const patternInput_1 = useReact_useReducer_2B9E6EA0((state, msg) => update(patternInput[1], state, msg), void 0);
    const dispatch = patternInput_1[1];
    const patternInput_2 = useKanbanContent();
    const isLoading = patternInput_2[0];
    useReact_useEffect_Z101E1A95(() => {
        let matchValue, set2;
        if (!isLoading) {
            const response = (matchValue = SimpleJson_tryParse(patternInput_2[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(KanbanContentResponse$reflection())) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()).data;
            dispatch(new AppStateAction(0, response, (set2 = ofArray(map_1((s) => s.id, response.swimlanes, Int32Array), {
                Compare: (x, y) => comparePrimitives(x, y),
            }), intersect(ofSeq(patternInput[0], {
                Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
            }), set2))));
        }
    }, [isLoading]);
    return React_contextProvider_34D9BBBD(appContext, [patternInput_1[0], dispatch], createElement(App, null));
}

