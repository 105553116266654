import { AppStateAction, WorkItem, decodeCellId } from "./Hooks.fs.js";
import { Array_insertAt, Array_removeItem } from "./Helpers.fs.js";
import { FSharpMap__get_Item } from "./.fable/fable-library.3.1.15/Map.js";
import { mapIndexed } from "./.fable/fable-library.3.1.15/Array.js";
import { ignore, startImmediate } from "./.fable/fable-library.3.1.15/Async.js";
import { Headers_authorization, Headers_contentType, Http_header, Http_request, Http_content, Http_method, Http_send } from "./.fable/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { BodyContent, HttpMethod } from "./.fable/Fable.SimpleHttp.3.0.0/Types.fs.js";
import { Convert_serialize } from "./.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";
import { interpolate, printf, toText, join } from "./.fable/fable-library.3.1.15/String.js";
import { collect, singleton, append, delay, toArray } from "./.fable/fable-library.3.1.15/Seq.js";
import { rangeDouble } from "./.fable/fable-library.3.1.15/Range.js";
import { createTypeInfo } from "./.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { anonRecord_type, string_type } from "./.fable/fable-library.3.1.15/Reflection.js";
import { kanbanerApiToken, kanbanerApiEndpoint } from "./Common.fs.js";

export function handleDragEnd(appState, dispatch, dropResult, _arg1) {
    let array_4, req_3, req_2;
    const matchValue = [appState, dropResult.destination];
    let pattern_matching_result, destination, items;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            destination = matchValue[1];
            items = matchValue[0].GridState;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const srcCellKey = decodeCellId(dropResult.source.droppableId);
            const dstCellKey = decodeCellId(destination.droppableId);
            let dstCellItems;
            if (dropResult.source.droppableId === destination.droppableId) {
                const colItemsWithoutSource = Array_removeItem(dropResult.source.index, FSharpMap__get_Item(items, dstCellKey));
                const updatedColItems = mapIndexed((i, wi) => (new WorkItem(wi.id, wi.content, wi.class_of_service, i, wi.date_created)), Array_insertAt(destination.index, FSharpMap__get_Item(items, dstCellKey)[dropResult.source.index], colItemsWithoutSource));
                dispatch(new AppStateAction(1, dstCellKey, updatedColItems));
                dstCellItems = updatedColItems;
            }
            else {
                const newSrcCellItems = Array_removeItem(dropResult.source.index, FSharpMap__get_Item(items, srcCellKey));
                const newDstCellItems = mapIndexed((i_1, wi_1) => (new WorkItem(wi_1.id, wi_1.content, wi_1.class_of_service, i_1, wi_1.date_created)), (array_4 = FSharpMap__get_Item(items, dstCellKey), Array_insertAt(destination.index, FSharpMap__get_Item(items, srcCellKey)[dropResult.source.index], array_4)));
                dispatch(new AppStateAction(2, [srcCellKey, newSrcCellItems], [dstCellKey, newDstCellItems]));
                dstCellItems = newDstCellItems;
            }
            startImmediate(ignore(Http_send((req_3 = (req_2 = Http_method(new HttpMethod(1), Http_content(new BodyContent(1, Convert_serialize({
                query: join("", toArray(delay(() => append(singleton("mutation {\n"), delay(() => append(collect((i_2) => {
                    const item = dstCellItems[i_2];
                    return (i_2 !== destination.index) ? singleton(toText(printf("item_%d: update_work_items_item(id: %d, data: { sort: %d }) { id }\n"))(item.id)(item.id)(i_2)) : ((dstCellKey.tag === 1) ? singleton(toText(printf("item_%d: update_work_items_item(id: %d, data: { sort: %d, column: null, recurring_task: {id:%d}, recurring_task_status: \"%O\" }) { id }\n"))(item.id)(item.id)(i_2)(dstCellKey.fields[0])(dstCellKey.fields[1])) : singleton(toText(printf("item_%d: update_work_items_item(id: %d, data: { sort: %d, column: {id: %d}, swimlane: {id:%d} }) { id }\n"))(item.id)(item.id)(i_2)(dstCellKey.fields[0])(dstCellKey.fields[1])));
                }, rangeDouble(0, 1, dstCellItems.length - 1)), delay(() => singleton("}")))))))),
            }, createTypeInfo(anonRecord_type(["query", string_type])))), Http_request(toText(interpolate("%P()/graphql", [kanbanerApiEndpoint]))))), Http_header(Headers_contentType("application/json"), req_2)), Http_header(Headers_authorization(toText(interpolate("Bearer %P()", [kanbanerApiToken]))), req_3)))));
            break;
        }
        case 1: {
            break;
        }
    }
}

