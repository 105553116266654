import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.15/Seq.js";
import { spreadProps, kanbanerApiToken, kanbanerApiEndpoint, spreadStyleProps, listGutter } from "./Common.fs.js";
import { map, value as value_44 } from "./.fable/fable-library.3.1.15/Option.js";
import { useReact_useRef_1505, useFeliz_React__React_useState_Static_1505, useReact_useCallback_93353E, useReact_useContext_37FA55CF } from "./.fable/Feliz.1.38.0/React.fs.js";
import { WorkItem as WorkItem_1, AppStateAction, appContext } from "./Hooks.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Popper from "@material-ui/core/Popper";
import { Interop_reactApi } from "./.fable/Feliz.1.38.0/Interop.fs.js";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { createElement } from "react";
import * as react from "react";
import { ofArray } from "./.fable/fable-library.3.1.15/List.js";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import { ignore, startImmediate } from "./.fable/fable-library.3.1.15/Async.js";
import { singleton as singleton_1 } from "./.fable/fable-library.3.1.15/AsyncBuilder.js";
import { Headers_contentType, Headers_authorization, Http_header, Http_request, Http_method, Http_content, Http_send } from "./.fable/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { HttpMethod, BodyContent } from "./.fable/Fable.SimpleHttp.3.0.0/Types.fs.js";
import { replace, printf, interpolate, toText } from "./.fable/fable-library.3.1.15/String.js";
import FileCopy from "@material-ui/icons/FileCopy";
import { SimpleJson_tryParse, SimpleJson_stringify } from "./.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { toString } from "./.fable/fable-library.3.1.15/Types.js";
import { Convert_fromJson } from "./.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";
import { createTypeInfo } from "./.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { anonRecord_type, int32_type, string_type } from "./.fable/fable-library.3.1.15/Reflection.js";
import { utcNow } from "./.fable/fable-library.3.1.15/Date.js";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import { createObj } from "./.fable/fable-library.3.1.15/Util.js";
import { EasyEditProps$1, ReactEasyEdit_easyEdit_791481ED } from "./Feliz.ReactEasyEdit.fs.js";
import { formatDistanceToNow } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from "@material-ui/icons/EditOutlined";

export function getItemStyle(isDragging, draggableStyle) {
    return toList(delay(() => append(singleton(["userSelect", "none"]), delay(() => append(singleton(["padding", (((((((listGutter * 2) + "px ") + (listGutter * 2)) + "px ") + (~(~(listGutter * 1.2)))) + "px ") + (listGutter * 2)) + "px"]), delay(() => append(singleton(["margin", ((((((0 + "px ") + 0) + "px ") + listGutter) + "px ") + 0) + "px"]), delay(() => append(isDragging ? singleton(["backgroundColor", "mediumspringgreen"]) : empty(), delay(() => {
        let notDraggingStyle, draggingStyle;
        if (draggableStyle == null) {
            return empty();
        }
        else {
            return draggingStyle = value_44(draggableStyle), spreadStyleProps(draggingStyle);
        }
    }))))))))));
}

export function OptionsPanel(optionsPanelInputProps) {
    const anchorRef = optionsPanelInputProps.anchorRef;
    const setOpen = optionsPanelInputProps.setOpen;
    const isOpen = optionsPanelInputProps.isOpen;
    const item = optionsPanelInputProps.item;
    const cellId = optionsPanelInputProps.cellId;
    const dispatch = useReact_useContext_37FA55CF(appContext)[1];
    const closePanel = useReact_useCallback_93353E(() => {
        setOpen(false);
    }, []);
    return MuiHelpers_createElement(Popper, [["open", isOpen], ["anchorEl", () => anchorRef.current], ["placement", "right-start"], ["style", {
        marginLeft: 8 + "px",
        marginTop: 8 + "px",
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ClickAwayListener, [["onClickAway", (_arg1_1) => {
        setOpen(false);
    }], ["children", createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const commonButtonProps = ofArray([["size", "small"], ["variant", "contained"]]);
            return append(singleton(MuiHelpers_createElement(Button, toList(delay(() => append(commonButtonProps, delay(() => append(singleton(["startIcon", react.createElement(Delete, {})]), delay(() => append(singleton(["onClick", (_arg1) => {
                startImmediate(singleton_1.Delay(() => {
                    let req_3, req_2;
                    return singleton_1.Bind(ignore(Http_send((req_3 = (req_2 = Http_content(new BodyContent(1, "{ \"status\": \"archived\" }"), Http_method(new HttpMethod(3), Http_request(toText(interpolate("%P()/items/work_items/%P()", [kanbanerApiEndpoint, item.id]))))), Http_header(Headers_authorization(toText(interpolate("Bearer %P()", [kanbanerApiToken]))), req_2)), Http_header(Headers_contentType("application/json"), req_3)))), () => {
                        dispatch(new AppStateAction(5, cellId, item.id));
                        closePanel();
                        return singleton_1.Zero();
                    });
                }));
            }]), delay(() => singleton(["children", "Archive"]))))))))))), delay(() => append(singleton(MuiHelpers_createElement(Button, toList(delay(() => append(commonButtonProps, delay(() => append(singleton(["style", {
                marginTop: 8 + "px",
            }]), delay(() => append(singleton(["startIcon", react.createElement(FileCopy, {})]), delay(() => append(singleton(["onClick", (_arg5) => {
                const sortValue = map((sort) => (sort + 1), item.sort);
                const title = toText(interpolate("(copy) %P()", [item.content]));
                const textContent = (cellId.tag === 1) ? SimpleJson_stringify({
                    class_of_service: "fixed_delivery_date",
                    recurring_task: cellId.fields[0],
                    recurring_task_status: toString(cellId.fields[1]),
                    sort: sortValue,
                    title: title,
                }) : SimpleJson_stringify({
                    column: cellId.fields[0],
                    sort: sortValue,
                    swimlane: cellId.fields[1],
                    title: title,
                });
                startImmediate(singleton_1.Delay(() => {
                    let req_13, req_12;
                    return singleton_1.Bind(Http_send((req_13 = (req_12 = Http_method(new HttpMethod(1), Http_content(new BodyContent(1, textContent), Http_request(toText(interpolate("%P()/items/work_items", [kanbanerApiEndpoint]))))), Http_header(Headers_authorization(toText(interpolate("Bearer %P()", [kanbanerApiToken]))), req_12)), Http_header(Headers_contentType("application/json"), req_13))), (_arg6) => {
                        let matchValue, response, matchValue_1;
                        const res = _arg6;
                        return singleton_1.Combine((matchValue = [res.statusCode, res.content], (matchValue[0] === 200) ? ((matchValue[1].tag === 0) ? (response = (matchValue_1 = SimpleJson_tryParse(matchValue[1].fields[0]), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(anonRecord_type(["data", anonRecord_type(["class_of_service", string_type], ["id", int32_type], ["title", string_type])]))) : (() => {
                            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                        })()), (dispatch(new AppStateAction(3, cellId, new WorkItem_1(response.data.id, response.data.title, response.data.class_of_service, sortValue, utcNow()))), singleton_1.Zero())) : (void 0, singleton_1.Zero())) : (void 0, singleton_1.Zero())), singleton_1.Delay(() => {
                            closePanel();
                            return singleton_1.Zero();
                        }));
                    });
                }));
            }]), delay(() => singleton(["children", "Duplicate"]))))))))))))), delay(() => singleton(MuiHelpers_createElement(Button, toList(delay(() => append(commonButtonProps, delay(() => append(singleton(["startIcon", react.createElement(SwapHoriz, {})]), delay(() => append(singleton(["style", {
                marginTop: 8 + "px",
            }]), delay(() => append(singleton(["onClick", (_arg3) => {
                const classesOfServices = ["intangible", "standard", "fixed_delivery_date", "expedite"];
                let nextClassOfService;
                const currentClassIdx = classesOfServices.findIndex((x) => (x === item.class_of_service)) | 0;
                nextClassOfService = classesOfServices[(currentClassIdx + 1) % classesOfServices.length];
                startImmediate(singleton_1.Delay(() => {
                    let req_8, req_7, req_6;
                    return singleton_1.Bind(ignore(Http_send((req_8 = (req_7 = (req_6 = Http_method(new HttpMethod(3), Http_request(toText(interpolate("%P()/items/work_items/%P()", [kanbanerApiEndpoint, item.id])))), Http_content(new BodyContent(1, toText(printf("{ \"class_of_service\": \"%s\" }"))(nextClassOfService)), req_6)), Http_header(Headers_authorization(toText(interpolate("Bearer %P()", [kanbanerApiToken]))), req_7)), Http_header(Headers_contentType("application/json"), req_8)))), () => {
                        dispatch(new AppStateAction(6, cellId, new WorkItem_1(item.id, item.content, nextClassOfService, item.sort, item.date_created)));
                        return singleton_1.Zero();
                    });
                }));
            }]), delay(() => singleton(["children", "Change class of service"])))))))))))))))));
        })))),
    })]])])]]);
}

export function WorkItem(workItemInputProps) {
    const item = workItemInputProps.item;
    const snapshot = workItemInputProps.snapshot;
    const provided = workItemInputProps.provided;
    const cellId = workItemInputProps.cellId;
    const patternInput = useReact_useContext_37FA55CF(appContext);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsHovered = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsEditing = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setOptionsPanelOpen = patternInput_3[1];
    const isOptionsPanelOpen = patternInput_3[0];
    const anchorRef = useReact_useRef_1505(void 0);
    return createElement("div", {
        style: {
            position: "relative",
        },
        ref: anchorRef,
        onMouseEnter: (_arg2) => {
            setIsHovered(true);
        },
        onMouseLeave: (_arg3) => {
            setIsHovered(false);
        },
        onClick: (e_1) => {
            e_1.stopPropagation();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(toList(delay(() => append(singleton(["ref", (elt) => {
            void provided.innerRef(elt);
        }]), delay(() => append(spreadProps(provided.draggableProps), delay(() => append(spreadProps(provided.dragHandleProps), delay(() => append(singleton(["className", toText(interpolate("work-item %P()", [replace(item.class_of_service, "_", "-")]))]), delay(() => append(singleton(["style", createObj(toList(delay(() => getItemStyle(snapshot.isDragging, provided.draggableProps.style))))]), delay(() => singleton(["children", Interop_reactApi.Children.toArray([ReactEasyEdit_easyEdit_791481ED(ofArray([new EasyEditProps$1(0, "textarea"), new EasyEditProps$1(1, item.content), new EasyEditProps$1(6, true), new EasyEditProps$1(4, () => {
            setIsEditing(true);
        }), new EasyEditProps$1(5, () => {
            setIsEditing(false);
        }), new EasyEditProps$1(2, (value_11) => {
            if (item.content !== value_11) {
                const newTitle = value_11;
                startImmediate(singleton_1.Delay(() => {
                    let req_3, req_2, req_1;
                    return singleton_1.Bind(ignore(Http_send((req_3 = (req_2 = (req_1 = Http_method(new HttpMethod(3), Http_request(toText(interpolate("%P()/items/work_items/%P()", [kanbanerApiEndpoint, item.id])))), Http_content(new BodyContent(1, SimpleJson_stringify({
                        title: newTitle,
                    })), req_1)), Http_header(Headers_authorization(toText(interpolate("Bearer %P()", [kanbanerApiToken]))), req_2)), Http_header(Headers_contentType("application/json"), req_3)))), () => {
                        patternInput[1](new AppStateAction(6, cellId, new WorkItem_1(item.id, newTitle, item.class_of_service, item.sort, item.date_created)));
                        return singleton_1.Zero();
                    });
                }));
            }
        })])), createElement("div", {
            style: {
                marginTop: 8 + "px",
                fontSize: 10 + "px",
                textAlign: "right",
            },
            children: formatDistanceToNow(item.date_created, {
                addSuffix: true,
            }),
        })])])))))))))))))))), delay(() => append((patternInput_1[0] ? (!patternInput_2[0]) : false) ? singleton(createElement("div", {
            key: "button",
            style: {
                position: "absolute",
                top: 12,
                right: 12,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["onClick", (e) => {
                setOptionsPanelOpen(!isOptionsPanelOpen);
            }], ["style", {
                color: "black",
                width: 20,
                height: 20,
            }], ["children", react.createElement(EditOutlined, {
                fontSize: "small",
            })]])]),
        })) : empty(), delay(() => singleton(createElement(OptionsPanel, {
            cellId: cellId,
            item: item,
            isOpen: isOptionsPanelOpen,
            setOpen: setOptionsPanelOpen,
            anchorRef: anchorRef,
        })))))))))),
    });
}

