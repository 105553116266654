import { useFeliz_React__React_useState_Static_1505, useReact_useContext_37FA55CF } from "./.fable/Feliz.1.38.0/React.fs.js";
import { AppStateAction, WorkItem, appContext } from "./Hooks.fs.js";
import { createElement } from "react";
import * as react from "react";
import { map, bind, defaultArg } from "./.fable/fable-library.3.1.15/Option.js";
import { tryLast } from "./.fable/fable-library.3.1.15/Array.js";
import { startImmediate } from "./.fable/fable-library.3.1.15/Async.js";
import { singleton } from "./.fable/fable-library.3.1.15/AsyncBuilder.js";
import { Headers_authorization, Headers_contentType, Http_header, Http_request, Http_content, Http_method, Http_send } from "./.fable/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { BodyContent, HttpMethod } from "./.fable/Fable.SimpleHttp.3.0.0/Types.fs.js";
import { SimpleJson_tryParse, SimpleJson_stringify } from "./.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { toString } from "./.fable/fable-library.3.1.15/Types.js";
import { interpolate, toText } from "./.fable/fable-library.3.1.15/String.js";
import { kanbanerApiToken, kanbanerApiEndpoint } from "./Common.fs.js";
import { Convert_fromJson } from "./.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";
import { createTypeInfo } from "./.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { anonRecord_type, int32_type, string_type } from "./.fable/fable-library.3.1.15/Reflection.js";
import { utcNow } from "./.fable/fable-library.3.1.15/Date.js";
import { Interop_reactApi } from "./.fable/Feliz.1.38.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

export function NewTaskForm(newTaskFormInputProps) {
    const onCancel = newTaskFormInputProps.onCancel;
    const cellItems = newTaskFormInputProps.cellItems;
    const cellId = newTaskFormInputProps.cellId;
    const patternInput = useReact_useContext_37FA55CF(appContext);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const taskTitle = patternInput_1[0];
    return createElement("form", {
        onSubmit: (e) => {
            e.preventDefault();
            const trimmedTaskTitle = taskTitle.trim();
            if (trimmedTaskTitle.length > 0) {
                const sortValue = defaultArg(bind((wi) => map((y) => (1 + y), wi.sort), tryLast(cellItems)), 0) | 0;
                startImmediate(singleton.Delay(() => {
                    let req_3, req_2;
                    return singleton.Bind(Http_send((req_3 = (req_2 = Http_method(new HttpMethod(1), Http_content(new BodyContent(1, (cellId.tag === 1) ? SimpleJson_stringify({
                        class_of_service: "fixed_delivery_date",
                        recurring_task: cellId.fields[0],
                        recurring_task_status: toString(cellId.fields[1]),
                        sort: sortValue,
                        title: trimmedTaskTitle,
                    }) : SimpleJson_stringify({
                        column: cellId.fields[0],
                        sort: sortValue,
                        swimlane: cellId.fields[1],
                        title: trimmedTaskTitle,
                    })), Http_request(toText(interpolate("%P()/items/work_items", [kanbanerApiEndpoint]))))), Http_header(Headers_contentType("application/json"), req_2)), Http_header(Headers_authorization(toText(interpolate("Bearer %P()", [kanbanerApiToken]))), req_3))), (_arg1) => {
                        const resp = _arg1;
                        const matchValue = [resp.statusCode, resp.content];
                        let pattern_matching_result;
                        if (matchValue[0] === 200) {
                            if (matchValue[1].tag === 0) {
                                pattern_matching_result = 0;
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                let response;
                                const matchValue_1 = SimpleJson_tryParse(matchValue[1].fields[0]);
                                if (matchValue_1 != null) {
                                    response = Convert_fromJson(matchValue_1, createTypeInfo(anonRecord_type(["data", anonRecord_type(["class_of_service", string_type], ["id", int32_type], ["title", string_type])])));
                                }
                                else {
                                    throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                                }
                                patternInput[1](new AppStateAction(3, cellId, new WorkItem(response.data.id, response.data.title, response.data.class_of_service, sortValue, utcNow())));
                                onCancel(e);
                                return singleton.Zero();
                            }
                            case 1: {
                                return singleton.Zero();
                            }
                        }
                    });
                }));
            }
        },
        children: Interop_reactApi.Children.toArray([createElement("textarea", {
            style: {
                boxSizing: "border-box",
                width: 100 + "%",
            },
            onChange: (ev) => {
                patternInput_1[1](ev.target.value);
            },
            rows: 3,
            value: taskTitle,
            placeholder: "Type the task title in...",
        }), createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "contained"], ["type", "submit"], ["children", "Save"]]), MuiHelpers_createElement(IconButton, [["size", "small"], ["style", {
                marginLeft: 8,
            }], ["onClick", (e_1) => {
                e_1.stopPropagation();
                onCancel(e_1);
            }], ["children", react.createElement(Close, {})]])]),
        })]),
    });
}

