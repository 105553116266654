import { Http_get } from "./.fable/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { interpolate, printf, toText } from "./.fable/fable-library.3.1.15/String.js";
import { kanbanerApiEndpoint } from "./Common.fs.js";
import { React_createContext_1AE444D8, useReact_useState_FCFD9EF, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.38.0/React.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.15/Async.js";
import { singleton } from "./.fable/fable-library.3.1.15/AsyncBuilder.js";
import { some } from "./.fable/fable-library.3.1.15/Option.js";
import { Record, Union } from "./.fable/fable-library.3.1.15/Types.js";
import { bool_type, tuple_type, array_type, anonRecord_type, record_type, class_type, option_type, string_type, int32_type, union_type } from "./.fable/fable-library.3.1.15/Reflection.js";
import { parse } from "./.fable/fable-library.3.1.15/Int32.js";

export function gqlQuery(query) {
    let arg20;
    return Http_get((arg20 = encodeURIComponent(query), toText(printf("%s/graphql?query=%s"))(kanbanerApiEndpoint)(arg20)));
}

export function useGql(query) {
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(gqlQuery(query), (_arg1) => {
            patternInput_1[1](_arg1[1]);
            patternInput[1](false);
            return singleton.Zero();
        })));
    }, []);
    return [patternInput[0], patternInput_1[0]];
}

export function useLocalStorage(key, initialValue) {
    const patternInput = useReact_useState_FCFD9EF(() => {
        try {
            const stringifiedValue = localStorage.getItem(key);
            return (stringifiedValue !== null) ? JSON.parse(stringifiedValue) : initialValue;
        }
        catch (e) {
            console.log(some(e));
            return initialValue;
        }
    });
    return [patternInput[0], (value_1) => {
        try {
            patternInput[1](value_1);
            localStorage.setItem(key, JSON.stringify(value_1));
        }
        catch (e_1) {
            console.log(some(e_1));
        }
    }];
}

export function useKanbanContent() {
    const patternInput = useGql("\r\n    {\r\n      recurring_tasks(sort: \"sort\") {\r\n        id\r\n        name\r\n        swimlane {\r\n          id\r\n        }\r\n      }\r\n      recurring_tasks_items: work_items(filter: {status: {_eq: \"published\"}, column: {id: {_null: true}}, recurring_task: { id: { _nnull: true}}}, sort: \"sort,date_created\") {\r\n        id\r\n        title\r\n        content\r\n        sort\r\n        class_of_service\r\n        date_created\r\n        date_updated\r\n        recurring_task {\r\n          id\r\n          name\r\n          swimlane {\r\n            id\r\n          }\r\n        }\r\n        recurring_task_status\r\n      }\r\n      work_items(filter: {status: {_eq: \"published\"}, column: {id: {_nnull: true}}}, sort: \"sort,date_created\") {\r\n        id\r\n        title\r\n        content\r\n        sort\r\n        class_of_service\r\n        date_created\r\n        date_updated\r\n        column {\r\n          id\r\n        }\r\n        swimlane {\r\n          id\r\n        }\r\n        recurring_task {\r\n          id\r\n          name\r\n        }\r\n        recurring_task_status\r\n      }\r\n      columns(sort: \"sort\") {\r\n        id\r\n        name\r\n        wip_limit\r\n      }\r\n      swimlanes(sort: \"sort\") {\r\n        id\r\n        name\r\n        wip_limit\r\n      }\r\n    }\r\n    ");
    return [patternInput[0], patternInput[1]];
}

export class RecurringTaskStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NextDue", "LastDone"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "last_done" : "next_due";
    }
}

export function RecurringTaskStatus$reflection() {
    return union_type("Hooks.RecurringTaskStatus", [], RecurringTaskStatus, () => [[], []]);
}

export class CellId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NormalCell", "RecurringTaskCell"];
    }
}

export function CellId$reflection() {
    return union_type("Hooks.CellId", [], CellId, () => [[["Item1", int32_type], ["Item2", int32_type]], [["Item1", int32_type], ["Item2", RecurringTaskStatus$reflection()]]]);
}

export function encodeCellId(cellId) {
    if (cellId.tag === 1) {
        if (cellId.fields[1].tag === 1) {
            return toText(interpolate("rt-last:%P()", [cellId.fields[0]]));
        }
        else {
            return toText(interpolate("rt-next:%P()", [cellId.fields[0]]));
        }
    }
    else {
        return toText(interpolate("n:%P()-%P()", [cellId.fields[0], cellId.fields[1]]));
    }
}

export function decodeCellId(key) {
    const typeDelimiterIdx = key.indexOf(":") | 0;
    const delimiterIdx = key.indexOf("-") | 0;
    const cellType = key.slice(void 0, (typeDelimiterIdx - 1) + 1);
    if (cellType === "n") {
        const patternInput = [key.slice(typeDelimiterIdx + 1, (delimiterIdx - 1) + 1), key.slice(delimiterIdx + 1, key.length)];
        return new CellId(0, parse(patternInput[0], 511, false, 32), parse(patternInput[1], 511, false, 32));
    }
    else {
        return new CellId(1, parse(key.slice(typeDelimiterIdx + 1, key.length), 511, false, 32), (cellType === "rt-next") ? (new RecurringTaskStatus(0)) : (new RecurringTaskStatus(1)));
    }
}

export class WorkItem extends Record {
    constructor(id, content, class_of_service, sort, date_created) {
        super();
        this.id = (id | 0);
        this.content = content;
        this.class_of_service = class_of_service;
        this.sort = sort;
        this.date_created = date_created;
    }
}

export function WorkItem$reflection() {
    return record_type("Hooks.WorkItem", [], WorkItem, () => [["id", int32_type], ["content", string_type], ["class_of_service", string_type], ["sort", option_type(int32_type)], ["date_created", class_type("System.DateTime")]]);
}

export class KanbanContentResponse extends Record {
    constructor(data) {
        super();
        this.data = data;
    }
}

export function KanbanContentResponse$reflection() {
    return record_type("Hooks.KanbanContentResponse", [], KanbanContentResponse, () => [["data", anonRecord_type(["columns", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["wip_limit", option_type(int32_type)]))], ["recurring_tasks", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["swimlane", anonRecord_type(["id", int32_type])]))], ["recurring_tasks_items", array_type(anonRecord_type(["class_of_service", string_type], ["content", string_type], ["date_created", string_type], ["id", int32_type], ["recurring_task", anonRecord_type(["id", int32_type], ["name", string_type])], ["recurring_task_status", string_type], ["sort", option_type(int32_type)], ["title", string_type]))], ["swimlanes", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["wip_limit", option_type(int32_type)]))], ["work_items", array_type(anonRecord_type(["class_of_service", string_type], ["column", anonRecord_type(["id", int32_type])], ["content", string_type], ["date_created", string_type], ["id", int32_type], ["sort", option_type(int32_type)], ["swimlane", anonRecord_type(["id", int32_type])], ["title", string_type]))])]]);
}

export class AppState extends Record {
    constructor(KanbanContent, GridState, HoveredCellId, CollapsedSwimlanes) {
        super();
        this.KanbanContent = KanbanContent;
        this.GridState = GridState;
        this.HoveredCellId = HoveredCellId;
        this.CollapsedSwimlanes = CollapsedSwimlanes;
    }
}

export function AppState$reflection() {
    return record_type("Hooks.AppState", [], AppState, () => [["KanbanContent", anonRecord_type(["columns", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["wip_limit", option_type(int32_type)]))], ["recurring_tasks", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["swimlane", anonRecord_type(["id", int32_type])]))], ["recurring_tasks_items", array_type(anonRecord_type(["class_of_service", string_type], ["content", string_type], ["date_created", string_type], ["id", int32_type], ["recurring_task", anonRecord_type(["id", int32_type], ["name", string_type])], ["recurring_task_status", string_type], ["sort", option_type(int32_type)], ["title", string_type]))], ["swimlanes", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["wip_limit", option_type(int32_type)]))], ["work_items", array_type(anonRecord_type(["class_of_service", string_type], ["column", anonRecord_type(["id", int32_type])], ["content", string_type], ["date_created", string_type], ["id", int32_type], ["sort", option_type(int32_type)], ["swimlane", anonRecord_type(["id", int32_type])], ["title", string_type]))])], ["GridState", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [CellId$reflection(), array_type(WorkItem$reflection())])], ["HoveredCellId", option_type(CellId$reflection())], ["CollapsedSwimlanes", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [int32_type])]]);
}

export class AppStateAction extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadKanbanContent", "UpdateCell", "UpdateTwoCells", "AddWorkItem", "CollapseSwimlane", "DeleteWorkItem", "UpdateWorkItem", "HoverCell", "HoverOut"];
    }
}

export function AppStateAction$reflection() {
    return union_type("Hooks.AppStateAction", [], AppStateAction, () => [[["content", anonRecord_type(["columns", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["wip_limit", option_type(int32_type)]))], ["recurring_tasks", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["swimlane", anonRecord_type(["id", int32_type])]))], ["recurring_tasks_items", array_type(anonRecord_type(["class_of_service", string_type], ["content", string_type], ["date_created", string_type], ["id", int32_type], ["recurring_task", anonRecord_type(["id", int32_type], ["name", string_type])], ["recurring_task_status", string_type], ["sort", option_type(int32_type)], ["title", string_type]))], ["swimlanes", array_type(anonRecord_type(["id", int32_type], ["name", string_type], ["wip_limit", option_type(int32_type)]))], ["work_items", array_type(anonRecord_type(["class_of_service", string_type], ["column", anonRecord_type(["id", int32_type])], ["content", string_type], ["date_created", string_type], ["id", int32_type], ["sort", option_type(int32_type)], ["swimlane", anonRecord_type(["id", int32_type])], ["title", string_type]))])], ["collapsedSwimlanes", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [int32_type])]], [["Item1", CellId$reflection()], ["Item2", array_type(WorkItem$reflection())]], [["Item1", tuple_type(CellId$reflection(), array_type(WorkItem$reflection()))], ["Item2", tuple_type(CellId$reflection(), array_type(WorkItem$reflection()))]], [["Item1", CellId$reflection()], ["Item2", WorkItem$reflection()]], [["collapseState", bool_type], ["Item2", int32_type]], [["Item1", CellId$reflection()], ["workItemId", int32_type]], [["Item1", CellId$reflection()], ["Item2", WorkItem$reflection()]], [["Item", CellId$reflection()]], []]);
}

export const appContext = React_createContext_1AE444D8("AppState", [void 0, (value) => {
}]);

